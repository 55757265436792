import React from 'react';
import { HashRouter, Route, Routes, useNavigate } from 'react-router-dom';

import LandingPage from './LandingPage';
import TeamPage from './TeamPage';

const Router = props => (
  <HashRouter>
    <Routes>
      <Route path="/" element={<LandingPage/>} />
      <Route path="/team" element={<TeamPage/>} />
      <Route path="/science" element={<LandingPage/>} />
    </Routes>
  </HashRouter>
);

export default Router;
