import {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useMediaQuery from '@mui/material/useMediaQuery';
import tinycolor from 'tinycolor2';

import Layout from '../components/Layout';
import SignupForm from '../components/SignupForm';

import config from '../lib/config';

const LandingPage = props => {
  const [isOpen, setIsOpen] = useState(false);
  const handleSignupClick = () => setIsOpen(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const lightPrimary = tinycolor(config().colors.primary).lighten(50).toString();
  return (
    <Layout>
      <Dialog
        open={isOpen}
        maxWidth='md'
        fullWidth={true}
        onClose={() => setIsOpen(false)}
      >
        <DialogContent>
          <Box style={{width: '100%', height: '80vh'}}>
            <SignupForm typeFormId={props.typeFormId}/>
          </Box>
        </DialogContent>
      </Dialog>
      <Box style={{backgroundColor: '#fff'}}>
        <Box
          style={{
            // width: '100%',
            paddingTop: '10vh',
            // paddingLeft: '2em',
            // paddingRight: '1em',
            paddingBottom: '4em',
          }}
        >
          <Grid container spacing={8} padding={2}>
            <Grid item md={7} xs={12}>
              <Box style={{
                // width: '100%'
              }}>
                <img src='/images/children-photos.png' style={{width: '100%'}}/>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Stack spacing={3}>
                <Stack>
                  <Typography variant='h1'>
                    Screen-free play that boosts early brain development
                  </Typography>
                </Stack>
                <Typography variant='body1' style={{paddingTop: '1em'}}>
                  {props.appName} embeds artificial intelligence into a beautiful alphabet
                  set crafted from natural maple wood. It is not only a fun and engaging
                  experience but a highly effective tool for teaching children
                  early language and literacy skills.
                </Typography>
                <Stack>
                  <Button
                    variant='contained'
                    size='lg'
                    onClick={handleSignupClick}
                  >
                    Join the Waitlist
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Stack
          direction='row'
          justifyContent='center'
          style={{marginTop: '3em'}}
          padding={2}
        >
          <Typography variant='h3' align='center'>
            We're grateful for our partners and supporters
          </Typography>
        </Stack>
        <Box style={{
          width: '100%',
          padding: '1em 0em 1em 0em',
          background: 'rgb(250,250,250)',
          borderTop: '1px solid rgb(230,230,230)',
          borderBottom: '1px solid rgb(230,230,230)',
          marginTop: '1em',
        }}>
          <Container maxWidth='lg' padding={2} justifyContent='center'>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12} md={3} style={{paddingTop: '2em'}}>
                <Stack direction='row' justifyContent='center'>
                  <img
                    src='/images/harvard-logo.png'
                    style={{maxWidth: '350px', height: 'auto'}}
                  />
                </Stack>
              </Grid>
{/*              <Grid item xs={12} md={2}>
                <Stack direction='row' justifyContent='center'>
                  <img
                    src='/images/nsf-logo.png'
                    style={{maxWidth: '100px', height: 'auto'}}
                  />
                </Stack>
              </Grid>*/}
              <Grid item xs={12} md={3}>
                <Stack direction='row' justifyContent='center'>
                  <img
                    src='/images/cps-logo.png'
                    style={{maxWidth: '150px', height: 'auto'}}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={3} style={{paddingTop: '2.5em'}}>
                <Stack direction='row' justifyContent='center'>
                  <img
                    src='/images/bill-and-melinda-gates-logo.png'
                    style={{maxWidth: '200px', height: 'auto'}}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12} md={3} style={{paddingTop: '2.5em'}}>
                <Stack direction='row' justifyContent='center'>
                  <img
                    src='/images/logos/harvard-medical-logo.png'
                    style={{maxWidth: '200px', height: 'auto'}}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={3} style={{paddingTop: '3.5em'}}>
                <Stack direction='row' justifyContent='center'>
                  <img
                    src='/images/logos/nsf-logo-v2.png'
                    style={{maxWidth: '250px', height: 'auto'}}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={3} style={{paddingTop: '4.5em'}}>
                <Stack direction='row' justifyContent='center'>
                  <img
                    src='/images/logos/walton-family-foundation-logo.png'
                    style={{maxWidth: '200px', height: 'auto'}}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box style={{paddingTop: '10em'}}>
          <Container maxWidth='lg'>
            <Grid container spacing={8}>
              <Grid item md={4} xs={12}>
                <Stack spacing={2}>
                  <Stack direction='row' justifyContent='center'>
                    <img src='/images/book.png' width='100px' height='100px'/>
                  </Stack>
                  <Stack direction='row' justifyContent='center'>
                    <Typography variant='h3'>
                      Effective
                    </Typography>
                  </Stack>
                  <Stack direction='row' justifyContent='center'>
                    <Typography variant='body1' align='center'>
                      {props.appName} applies evidence-based strategies to promote early language
                      and literacy, social-emotional learning, and executive functioning development.
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item md={4} xs={12}>
                <Stack spacing={2}>
                  <Stack direction='row' justifyContent='center'>
                    <img src='/images/tree.png' width='100px' height='100px'/>
                  </Stack>
                  <Stack direction='row' justifyContent='center'>
                    <Typography variant='h3'>
                      Natural
                    </Typography>
                  </Stack>
                  <Stack direction='row' justifyContent='center'>
                    <Typography variant='body1' align='center'>
                      {props.appName} gets kids off screens and helps connect them with nature.
                      Crafted from safe, chemical-free, sustainably-sourced hardwood.
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item md={4} xs={12}>
                <Stack spacing={2}>
                  <Stack direction='row' justifyContent='center'>
                    <img src='/images/brain.png' width='100px' height='100px'/>
                  </Stack>
                  <Stack direction='row' justifyContent='center'>
                    <Typography variant='h3'>
                      Individualized
                    </Typography>
                  </Stack>
                  <Stack direction='row' justifyContent='center'>
                    <Typography variant='body1' align='center'>
                      {props.appName} uses machine learning to differentiate and scaffold the
                      curriculum, ensuring children are optimally supported for growth.
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box
          style={{
            width: '100%',
            // x` height: '700px',
            marginTop: '10em',
            background: isSmallScreen ? lightPrimary : 'url(/images/mom-and-child.png) no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'right center',
            minHeight: '80vh',
          }}
        >
          <Grid container>
            <Grid item md={4} xs={12}>
              <Stack spacing={3} padding={8}>
                <Typography
                  variant='h1'
                  style={{
                    paddingTop: '2em',
                    color: 'rgb(0,0,0)'
                  }}
                >
                  Possibly the smartest letter set ever designed
                </Typography>
                <Typography variant='body1' style={{fontWeight: '450', fontSize: '1.4rem'}}>
                  {props.appName} boosts learning through hands-on, tactile
                  play that promotes language development and critical literacy
                  skills. {props.appName} uses formative assessment to guide instruction,
                  much like a highly-skilled educator, responsive to individual learner needs.
                  {/*Children are empowered to engage and learn on their own, or adults can blah blah*/}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        {/*<Box>
          <Container
            maxWidth='lg'
            style={{
              padding: '2em',
              marginTop: '10em'
            }}
          >
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <img
                  src='/images/father-and-son.jpg'
                  style={{maxWidth: '100%'}}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Stack spacing={3}>
                  <Typography variant='h1'>
                    We've done all the research so that you don't have to.
                  </Typography>
                  <Typography variant='body1'>
                    Our team includes Harvard-trained experts on childhood
                    behavioral psychology and best practices for teaching
                    literacy sklls. So you can rest assured that your child will
                    be learning with the most effective teaching methods.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>*/}
        {/*<Box>
          <Container maxWidth='lg' style={{padding: '2em', marginTop: '10em'}}>
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Stack spacing={3}>
                  <Typography variant='h1'>
                    Giving a head start to children with dyslexia and autism.
                  </Typography>
                  <Typography variant='body1'>
                    {props.appName}'s team is developing machine learning algorithms
                    to accelerate diagnosis for children with autism and dyslexia.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item md={6} xs={12}>
                <img
                  src='/images/child-with-tablet.jpg'
                  style={{maxWidth: '100%'}}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>*/}
        <Box style={{paddingTop: '10em'}}>
{/*          <Typography variant='h1'>
            Sign up to get notified when
          </Typography>*/}
          <Stack direction='row' justifyContent='center'>
            <Button variant='contained' size='large' onClick={handleSignupClick}>
              Join the Waitlist
            </Button>
          </Stack>
        </Box>
      </Box>
    </Layout>
  );
};

LandingPage.defaultProps = {
  appName: config().appName,
  typeFormId: config().typeFormId,
};

export default LandingPage;
