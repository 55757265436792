import Box from '@mui/material/Box';

import TopBar from './TopBar';
import Footer from './Footer';

const Layout = props => (
  <Box>
    <TopBar />
    <Box>
      {props.children}
    </Box>
    <Footer />
  </Box>
);

export default Layout;
