import {createTheme} from '@mui/material/styles';
import {
  lightBlue,
  green
} from '@mui/material/colors';

// import BrandFont from '../styles/fonts/Raleway/Raleway-VariableFont_wght.ttf';
// import ComfortaaTtf from '../styles/fonts/Comfortaa/Comfortaa-VariableFont_wght.ttf';
// import ComfortaaRegular from '../styles/fonts/Comfortaa/static/Comfortaa-Regular.ttf';
// import ComfortaaMedium from '../styles/fonts/Comfortaa/static/Comfortaa-Medium.ttf';
// import ComfortaaBold from '../styles/fonts/Comfortaa/static/Comfortaa-Bold.ttf';

import config from './config';

const baseFonts = [
  'Inter',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

const defaultThemeConfig = {
  palette: {
    primary: {
      // ...green,
      // contrastText: '#ffffff'
      main: config().colors.primary,
      contrastText: '#fff',
    },
    // secondary: {
    //   main: config().colors.secondary,
    // },
    // info: {
    //   main: config().colors.info,
    // },
    // success: {
    //   main: config().colors.success,
    // },
    // error: {
    //   main: config().colors.danger,
    // },
    // warning: {
    //   main: config().colors.warning,
    // },
  },
  typography: {
    fontFamily: baseFonts.join(','),
    fontSize: 16,
    h1: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      color: 'rgb(75,75,75)',
      fontFamily: [config().brandFont, ...baseFonts].join(','),
    },
    h2: {
      fontSize: '2.2rem',
      fontWeight: 'bold',
      color: 'rgb(75,75,75)',
      fontFamily: [config().brandFont, ...baseFonts].join(','),
    },
    h3: {
      fontSize: '2.0rem',
      fontWeight: 'bold',
      color: 'rgb(75,75,75)',
      fontFamily: [config().brandFont, ...baseFonts].join(','),
    },
    h4: {
      fontSize:'1.8rem',
      fontWeight: 'bold',
      color: 'rgb(75,75,75)',
      fontFamily: [config().brandFont, ...baseFonts].join(','),
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
      color: 'rgb(75,75,75)',
      fontFamily: [config().brandFont, ...baseFonts].join(','),
    },
    h6: {
      fontWeight: 'bold',
      fontSize: '1.25rem',
      color: 'rgb(75,75,75)',
      fontFamily: [config().brandFont, ...baseFonts].join(','),
    },
    subtitle1: {
      fontWeight: 'bold',
      color: 'rgb(30,30,30)',
      // color: 'rgb(100,100,100)'
      // fontFamily: ['GT America', ...baseFonts].join(','),
    },
    subtitle2: {
      fontWeight: '450',
      color: 'rgb(30,30,30)',
      // fontFamily: ['GT America', ...baseFonts].join(','),
    },
    body1: {
      fontSize: '1.3rem',
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: '25px',
          fontSize: '1.25rem',
          // ...(ownerState.variant === 'contained' &&
          //   ownerState.color === 'success' && {
          //     // backgroundColor: '#202020',
          //     color: '#fff',
          //   }),
        }),
      },
    },
    // MuiCssBaseline: {
    //   styleOverrides: `
    //     @font-face {
    //       font-family: 'Comfortaa';
    //       src: local('Comfortaa-Regular'), local('Comfortaa-Bold') url(${ComfortaaTtf}) format('ttf');
    //       unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    //     }
    //   `,
    // },
    //   MuiListItemIcon: {
    //   styleOverrides: {
    //     root: {
    //       fontSize: '0.8rem',
    //     }
    //   }
    // },
    // MuiListItemText: {
    //   styleOverrides: {
    //     root: {
    //       fontSize: '0.8rem',
    //     }
    //   }
    // },
  },
  // status: {
  //   danger: ,
  // },
};

const theme = (themeConfig = defaultThemeConfig) => createTheme(themeConfig);

export default theme;
