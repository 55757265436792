import { Widget } from '@typeform/embed-react';

const Form = props => {
  return (
    <Widget
      id={props.typeFormId}
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  );
};

export default Form;
