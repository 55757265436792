// import logo from './logo.svg';
// import './App.css';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import theme from './lib/theme';
import Router from './pages/Router';

function App() {
  return (
    <ThemeProvider theme={theme()}>
      <Router />
    </ThemeProvider>
  );
}

export default App;
