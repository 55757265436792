import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Layout from '../components/Layout';

const advisors = [
  {
    name: 'Ying Xu',
    title: 'Assistant Professor of Learning Sciences and Technology',
    org: 'Marsal Family School of Education at the University of Michigan',
    imgSrc: '/images/headshots/ying-xu.png',
    logoSrc: ['/images/logos/u-of-m.png'],
  },
  {
    name: 'Meredith L. Rowe',
    title: <>Saul Zaentz Professor of Early Learning and Development <br/> Faculty Co-Chair</>,
    org: 'Human Development and Education Program',
    imgSrc: '/images/headshots/meredith-rowe.png',
    logoSrc: ['/images/logos/harvard-education.png'],
  },
  {
    name: 'Myron Belfer',
    title: <>Professor of Psychiatry <br/>Senior Associate in Psychiatry</>,
    org: 'Harvard Medical School & Children\'s Hospital Boston',
    imgSrc: '/images/headshots/myron-belfer.png',
    logoSrc: [
      '/images/logos/harvard-medical.png',
      '/images/logos/boston-childrens-hospital.png'
    ],
  },
  {
    name: 'Bill de Blasio',
    title: 'Former Mayor',
    org: 'New York City',
    imgSrc: '/images/headshots/bill-deblasio.png',
    logoSrc: ['/images/logos/nyc.png'],
  },
  // {
  //   name: 'Karthik Ganesan',
  //   title: '',
  //   org: '',
  //   imgSrc: '/images/headshots/',
  //   logoSrc: ['/images/logos/'],
  // }
];

const TeamPage = props => {
  return (
    <Layout>
      <Box style={{paddingTop: '4em', width: '100%'}}>
        <Stack spacing={3}>
          <Stack direction='row' justifyContent='center'>
            <Container maxWidth='md'>
              <Typography variant='h1' align='center'>
                Experts & Advisors
              </Typography>
              <Typography
                variant='body1'
                align='center'
                style={{paddingTop: '1em', paddingBottom: '1em'}}
              >
                MindsEmerge works with leaders and experts in neuroscience,
                psychology, behavioral economics, artificial intelligence,
                and early learning and development. Their work informs
                everything we do.
              </Typography>
              <Divider />
            </Container>
          </Stack>
        </Stack>
        <Container maxWidth='xl'>
          <Grid container padding={2} spacing={4} style={{paddingTop: '8em'}}>
            {advisors.map(a =>
              <Grid item xs={12} md={3} lg={3} xl={3} justifyContent='center'>
                <Stack direction='row' justifyContent='center'>
                  <img
                    src={a.imgSrc}
                    alt={a.name}
                    style={{width: '100%', maxWidth: '200px'}}
                  />
                </Stack>
                <Typography variant='h5' align='center' style={{paddingTop: '1em'}}>
                  {a.name}
                </Typography>
                <Typography variant='body1' align='center' style={{minHeight: '4.5em'}}>
                  {a.title}
                </Typography>
                  {a.logoSrc.map(l =>
                    <Stack direction='row' justifyContent='center'>
                      <img
                        src={l}
                        alt={a.org}
                        style={{width: '70%', maxWidth: '200px'}}
                      />
                    </Stack>
                  )}
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export default TeamPage;
