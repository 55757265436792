import React from 'react';
import PropTypes from 'prop-types';
import {DateTime} from 'luxon';

import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import config from '../lib/config';

const FooterLink = props => {
  const {LinkComp, to, name} = props;
  return (
    <span style={{
      paddingRight: '1em',
      fontSize: '1.1em',
      fontFamily: 'GT America',
    }}>
      <LinkComp
        to={to}
        style={{
          fontSize: '1.5rem',
          textDecoration: 'none'
        }}
      >
        {name}
      </LinkComp>
    </span>
  );
};

const Footer = (props) => (
  <Container maxSize='lg' style={{paddingTop: '10em', paddingBottom: '5em'}}>
    <Stack direction='row' justifyContent='center'>
      {props.links.map(l =>
        <FooterLink LinkComp={props.LinkComp} href={l.to} name={l.name} />
      )}
    </Stack>
    <Stack direction='row' justifyContent='center'>
      <Typography variant='body1' style={{color: 'rgb(150,150,150)'}}>
        © {props.companyName} {DateTime.local().toFormat('yyyy')}  all rights reserved. | Designed by {props.companyName}.
      </Typography>
    </Stack>
  </Container>
);

Footer.defaultProps = {
  LinkComp: Link,
  links: [
    // {to: '/terms', name: 'Terms'},
    // {to: '/about', name: 'About Us'},
    // {to: '/story', name: 'Our Story'},
  ],
  companyName: config().companyName,
};

Footer.propTypes = {
  LinkComp: PropTypes.any,
  links: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string,
    name: PropTypes.string,
  })),
};

export default Footer;
