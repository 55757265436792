const baseConfig = {
  appName: 'MindsEmerge',
  companyName: 'MindsEmerge, Inc.',
  apiUrl: `${process.env.REACT_APP_API_URL}`,
  colors: {
    primary: '#1a8b9d', // dark cyan
    // primary: 'rgb(125, 187, 145)', // earthy
    // primary: 'rgb(216, 181, 137)', // oak
    // primary: 'rgb(139, 97, 69)', // dark oak
    // primary: 'rgb(104, 90, 78)', // coffee
    secondary: '#b2d430',
    info: 'rgb(3, 155, 229)',
    success: 'rgb(3, 155, 229)',
    error: '#f44336',
    warning: 'rgb(3, 155, 229)',
    muted: 'rgb(128, 128, 128)',
  },
  typeFormId: 'eOTJdrhs',
  brandFont: 'Comfortaa',
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  recaptchaPublicKey: process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY,
};

const config = () => baseConfig;

export default config;
