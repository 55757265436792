import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {useNavigate} from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import config from '../lib/config';

const links = [
  {
    text: 'Home',
    to: '/',
  },
  {
    text: 'Expertise',
    to: '/team',
  },
  // {
  //   text: 'Science',
  //   to: '/science'
  // },
]
const Logo = props => {
  return (
    <img
      alt='MindsEmerge logo'
      src='/images/MindsEmerge-logo-bright.png'
      style={{
        marginTop: '1em',
        width: '100%',
        maxWidth: '350px',
      }}
    />
  );
};

const NavLink = props => {
  const navigate = useNavigate();
  return (
    <Button
      style={{marginTop: '1em', fontWeight: 'bold'}}
      onClick={() => navigate(props.to)}
    >
      {props.text}
    </Button>
  );
};

const TopBar = props => {
  return (
      <>
        <Box
          style={{
            width: '100%',
            padding: '0.25em 0em 0.25em 0em',
            backgroundColor: config().colors.primary
        }}>
          <Stack direction='row' justifyContent='center' style={{width: '100%'}}>
            <Typography variant='subtitle1' style={{color: '#ffffff'}}>
              Now in early access
            </Typography>
          </Stack>
        </Box>
        <Box
          style={{
            height: '90px',
            borderBottom: '1px solid #e1e1e1',
          }}
        >
          <Container maxWidth='large'>
            <Grid container>
              <Grid item xs={0} md={4} sx={{display: {xs: 'none', md: 'block'}}}/>
              <Grid item md={4} xs={12} justifyContent='center'>
                <Stack direction='row' justifyContent='center'>
                  {Logo(props)}
                </Stack>
              </Grid>
              <Grid
                item
                xs={4}
                justifyContent='right'
                sx={{display: { xs: 'none', lg: 'block' }}}
              >
                <Stack
                  direction='row'
                  justifyContent='right'
                  spacing={3}
                  style={{paddingRight: '3em'}}
                >
                  {links.map(l => <NavLink {...l}/>)}
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </>
    );
};

export default TopBar;
